import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => {
  const bodyText = data.text.body.childMarkdownRemark.html
  const headerPicture = data.text.headerPicture
  
  return (
    <Layout headerPicture={headerPicture}>
      <Helmet>
        <title>{data.text.title}</title>
      </Helmet>
      <div
        className="body"
        dangerouslySetInnerHTML={{
          __html: bodyText,
        }}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    text: contentfulPage(path: {eq: "liity-kuoroon"}) {
        title
        body {
          childMarkdownRemark {
            html
          }
        }
        headerPicture {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          file {
            details {
              image {
                width
                height
              }
            }
            url
          }
        }
    }
  }
`
